<script lang="ts">
  import { Confetti } from "svelte-confetti"
  import { Shuffle, SortAsc, Trophy } from "lucide-svelte"
  import { Button } from "$lib/components/ui/button"
  import * as Card from "$lib/components/ui/card"
  import * as Table from "$lib/components/ui/table"
  import { onMount } from "svelte"
  import { Input } from "$lib/components/ui/input"
  import { Textarea } from "$lib/components/ui/textarea"
  import { Label } from "$lib/components/ui/label"
  import CtaImage from "$lib/components/Icons/CtaImage.svelte"

  // State for the name list
  let namesText = $state("")
  let names = $state<
    Array<{ name: string; weight: number; winPercentage?: number }>
  >([])
  let isSpinning = $state(false)
  let showConfetti = $state(false)
  let currentWinner = $state<string | null>(null)
  let wheelElement: SVGSVGElement
  let previousEndDegree = 0
  let wheelContainer: HTMLDivElement
  let rotation = 0
  let randomOrgApiKey = "14f748fe-b61a-48f7-968c-455c81d002a2"
  let usingFallbackRandom = $state(false)

  // Function to parse names from textarea
  function parseNames() {
    const nameList = namesText
      .split("\n")
      .map((name) => name.trim())
      .filter((name) => name.length > 0)

    // Preserve weights for existing names
    const weightMap = new Map(names.map((entry) => [entry.name, entry.weight]))

    names = nameList.map((name) => ({
      name,
      weight: weightMap.has(name) ? weightMap.get(name)! : 1,
    }))

    updateWinPercentages()
  }

  // Function to shuffle names
  function shuffleNames() {
    const shuffled = [...names]
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]
    }
    names = shuffled

    // Also update the textarea with shuffled names
    namesText = shuffled.map((entry) => entry.name).join("\n")
  }

  // Function to sort names alphabetically
  function sortNames() {
    names = [...names].sort((a, b) => a.name.localeCompare(b.name))

    // Also update the textarea with sorted names
    namesText = names.map((entry) => entry.name).join("\n")
  }

  // Function to update weight
  function updateWeight(index: number, newWeight: number) {
    if (newWeight < 1) newWeight = 1
    names[index].weight = newWeight
    names = [...names] // Trigger reactivity
    updateWinPercentages()
  }

  // Function to calculate win percentages
  function updateWinPercentages() {
    const totalWeight = names.reduce((sum, entry) => sum + entry.weight, 0)
    names.forEach((entry) => {
      entry.winPercentage =
        totalWeight > 0 ? (entry.weight / totalWeight) * 100 : 0
    })
  }

  // Helper functions for the wheel
  function polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number,
  ) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    }
  }

  function describeArc(
    x: number,
    y: number,
    radius: number,
    startAngle: number,
    endAngle: number,
  ) {
    const start = polarToCartesian(x, y, radius, endAngle)
    const end = polarToCartesian(x, y, radius, startAngle)
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"
    return [
      "M",
      x,
      y,
      "L",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
      "Z",
    ].join(" ")
  }

  // Function to create wheel segments
  function createWheel() {
    if (!names.length) return []

    // Calculate total weight
    const totalWeight = names.reduce((sum, entry) => sum + entry.weight, 0)

    // Reset current angle to 0 and distribute 360 degrees among participants
    let currentAngle = 0
    return names.map((entry, i) => {
      // Calculate angle based on weight
      const segmentAngle = (360 * entry.weight) / totalWeight

      const segment = {
        name: entry.name,
        weight: entry.weight,
        startAngle: currentAngle,
        angle: segmentAngle,
        color: `hsl(${(i * 137.5) % 360}, 70%, 80%)`,
      }

      // Update current angle for next segment
      currentAngle += segmentAngle
      return segment
    })
  }

  // Function to get random degrees from Random.org
  async function getRandomDegrees() {
    try {
      const response = await fetch("https://api.random.org/json-rpc/4/invoke", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jsonrpc: "2.0",
          method: "generateIntegers",
          params: {
            apiKey: randomOrgApiKey,
            n: 1,
            min: 1800, // Start at 5 full rotations (5 * 360)
            max: 2520, // Up to 7 full rotations (7 * 360)
            replacement: true,
            base: 10,
          },
          id: 1,
        }),
      })

      const data = await response.json()

      if (data.error) {
        console.error("Random.org API error:", data.error)
        usingFallbackRandom = true
        // Fallback to Math.random() with similar range
        return 1800 + Math.floor(Math.random() * 720) // Between 5-7 rotations
      }

      return data.result.random.data[0]
    } catch (error) {
      console.error("Error fetching random number:", error)
      usingFallbackRandom = true
      // Fallback to Math.random() with similar range
      return 1800 + Math.floor(Math.random() * 720) // Between 5-7 rotations
    }
  }

  // Function to spin the wheel
  async function spin() {
    if (isSpinning || names.length === 0) return
    isSpinning = true
    currentWinner = null
    showConfetti = false
    usingFallbackRandom = false

    // Get random degrees from Random.org or fallback to Math.random
    const randomAdditionalDegrees = await getRandomDegrees()
    const newEndDegree = previousEndDegree + randomAdditionalDegrees

    const animation = wheelElement.animate(
      [
        { transform: `rotate(${previousEndDegree}deg)` },
        { transform: `rotate(${newEndDegree}deg)` },
      ],
      {
        duration: 3000,
        easing: "cubic-bezier(0.440, -0.205, 0.000, 1.130)",
        fill: "forwards",
      },
    )

    animation.onfinish = () => {
      isSpinning = false
      const finalRotation = newEndDegree % 360
      const adjustedRotation = (360 - finalRotation) % 360

      const segments = createWheel()
      const winningSegment = segments.find((segment) => {
        return (
          adjustedRotation >= segment.startAngle &&
          adjustedRotation < segment.startAngle + segment.angle
        )
      })

      if (winningSegment) {
        currentWinner = winningSegment.name
        showConfetti = true
      }
    }

    previousEndDegree = newEndDegree
  }

  // Format name for display in wheel
  function formatName(fullName: string) {
    if (fullName.length > 15) {
      return fullName.substring(0, 12) + "..."
    }
    return fullName
  }

  // Handle weight change
  function handleWeightChange(index: number, value: string) {
    const numValue = parseInt(value)
    if (!isNaN(numValue)) {
      updateWeight(index, numValue)
    }
  }

  // Initialize on mount
  onMount(() => {
    // Example names
    namesText =
      "John Smith\nJane Doe\nAlex Johnson\nSamantha Williams\nMichael Brown"
    parseNames()
  })
</script>

<svelte:head>
  <title
    >Free Random Name Picker Wheel - Draw a random name (or names) from a list |
    Promo Amp</title
  >
  <meta
    name="description"
    content="Easily choose winners for your giveaways, sweepstakes, and raffles with our free random name picker tool. Simply enter a list of names, and let the tool do the rest!"
  />
  <meta
    name="keywords"
    content="random name picker, name wheel, random winner, random selector, giveaway tool, decision maker"
  />
  <meta
    property="og:title"
    content="Random Name Picker Wheel - Draw a random name (or names) from a list |
    Promo Amp"
  />
  <meta
    property="og:description"
    content="Randomly select names with our free wheel spinner tool. Customize weights to adjust probabilities."
  />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://img.promoamp.com/og_image.png" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="Random Name Picker Wheel - Draw a random name (or names) from a list |
    Promo Amp"
  />
  <meta
    name="twitter:description"
    content="Randomly select names with our free wheel spinner tool. Customize weights to adjust probabilities."
  />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://promoamp.com/random-name-picker" />
</svelte:head>

<div class="container mx-auto py-8 px-4 mt-20">
  <div
    class="text-center w-fit mx-auto mb-4 bg-purple-500 text-white text-sm font-semibold px-3 py-1 rounded-full"
  >
    Free Tool
  </div>
  <h1 class="text-3xl font-bold mb-6 text-center">Random Name Picker</h1>
  <h2 class="text-xl mb-12 text-center w-full sm:w-1/2 mx-auto">
    Use Promo Amp's free Random Name Winner tool to effortlessly choose winners
    for your giveaways, sweepstakes, and raffles.
  </h2>

  <div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
    <!-- Left Column: Input and Controls -->
    <Card.Root class="shadow-sm">
      <Card.Header>
        <Card.Title
          ><h2 class="text-xl font-bold">Step 1: Enter Names</h2></Card.Title
        >
        <Card.Description>
          Enter up to 1,000 names in the form below, one per line, to add them
          to the draw. Click "Save/Update Names" to save your names.
        </Card.Description>
      </Card.Header>
      <Card.Content>
        <div class="space-y-4">
          <div>
            <Label for="names-input">Enter Names</Label>
            <Textarea
              id="names-input"
              bind:value={namesText}
              placeholder="Enter names, one per line"
              rows={10}
              class="w-full"
            />
          </div>

          <div class="flex flex-wrap gap-2">
            <Button
              onclick={parseNames}
              variant="default"
              class="w-auto text-center py-3 px-6 rounded-lg mb-6 bg-purple-500 hover:bg-purple-600 text-white"
              >Save/Update Names</Button
            >
            <Button
              onclick={shuffleNames}
              variant="outline"
              class="flex items-center gap-2 w-full sm:w-auto"
            >
              <Shuffle size={16} />
              Shuffle
            </Button>
            <Button
              onclick={sortNames}
              variant="outline"
              class="flex items-center gap-2 w-full sm:w-auto"
            >
              <SortAsc size={16} />
              Sort A-Z
            </Button>
          </div>
        </div>
      </Card.Content>
    </Card.Root>

    <!-- Right Column: Wheel -->
    <Card.Root class="shadow-sm">
      <Card.Header>
        <Card.Title
          ><h2 class="text-xl font-bold">Step 2: Spin the Wheel</h2></Card.Title
        >
        <Card.Description class="mb-12">
          Click the "Spin the Wheel" button below to pick a random winner(s).
        </Card.Description>
      </Card.Header>
      <Card.Content class="flex flex-col items-center justify-center">
        {#if showConfetti}
          <Confetti />
        {/if}

        <div
          class="relative w-[300px] h-[300px] mb-4"
          bind:this={wheelContainer}
        >
          <svg
            bind:this={wheelElement}
            width="300"
            height="300"
            viewBox="0 0 400 400"
          >
            <g transform="rotate({rotation}, 200, 200)">
              {#each createWheel() as segment}
                <path
                  d={describeArc(
                    200,
                    200,
                    190,
                    segment.startAngle,
                    segment.startAngle + segment.angle,
                  )}
                  fill={segment.color}
                  stroke="white"
                  stroke-width="1"
                />
                {#if segment.angle > 5}
                  <text
                    x="200"
                    y="200"
                    transform={`
                      rotate(${segment.startAngle + segment.angle / 2 - 90}, 200, 200)
                      translate(70, 0)
                    `}
                    text-anchor="start"
                    dominant-baseline="middle"
                    font-size={segment.angle < 10
                      ? "10"
                      : segment.angle < 20
                        ? "12"
                        : "14"}
                    class="font-medium"
                  >
                    {formatName(segment.name)}
                  </text>
                {/if}
              {/each}
            </g>

            <!-- Center circle -->
            <circle
              cx="200"
              cy="200"
              r="12"
              fill="white"
              stroke="#ccc"
              stroke-width="2"
            />
          </svg>

          <!-- Pointer -->
          <div
            class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-2 z-20"
          >
            <div
              class="w-0 h-0 border-l-[15px] border-l-transparent border-r-[15px] border-r-transparent border-t-[30px] border-t-red-500"
            ></div>
          </div>
        </div>

        {#if currentWinner && !isSpinning}
          <div class="text-xl font-bold text-center mt-4 animate-bounce">
            Winner: {currentWinner}! 🎉
          </div>
        {/if}

        <Button
          disabled={isSpinning || names.length === 0}
          onclick={spin}
          class="w-auto text-center py-3 px-6 rounded-lg mb-6 bg-purple-500 hover:bg-purple-600 text-white"
        >
          <Trophy size={20} />
          Spin the Wheel
        </Button>

        {#if usingFallbackRandom}
          <div class="text-xs text-gray-500 mt-2">
            Using local random generator (Random.org unavailable)
          </div>
        {/if}
      </Card.Content>
    </Card.Root>
  </div>

  <!-- Name Weights Table -->
  <Card.Root class="shadow-sm mt-8">
    <Card.Header>
      <Card.Title
        ><h2 class="text-xl font-bold">Name Weights (Optional)</h2></Card.Title
      >
      <Card.Description>
        Adjust the weight of each name to change its probability of being
        selected.
      </Card.Description>
    </Card.Header>
    <Card.Content>
      {#if names.length > 0}
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.Head class="w-[50px]">#</Table.Head>
              <Table.Head>Name</Table.Head>
              <Table.Head class="w-[150px]">Weight</Table.Head>
              <Table.Head class="w-[100px] text-right">Win %</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {#each names as entry, i}
              <Table.Row>
                <Table.Cell class="font-medium">{i + 1}</Table.Cell>
                <Table.Cell>{entry.name}</Table.Cell>
                <Table.Cell>
                  <div class="flex items-center gap-2">
                    <Button
                      variant="outline"
                      size="sm"
                      class="h-8 w-8 p-0"
                      onclick={() => updateWeight(i, entry.weight - 1)}
                      disabled={entry.weight <= 1}>-</Button
                    >
                    <Input
                      type="number"
                      min="1"
                      class="w-16 h-8"
                      value={entry.weight}
                      onchange={(e) =>
                        handleWeightChange(
                          i,
                          (e.currentTarget as HTMLInputElement).value,
                        )}
                    />
                    <Button
                      variant="outline"
                      size="sm"
                      class="h-8 w-8 p-0"
                      onclick={() => updateWeight(i, entry.weight + 1)}
                      >+</Button
                    >
                  </div>
                </Table.Cell>
                <Table.Cell class="text-right"
                  >{entry.winPercentage?.toFixed(1)}%</Table.Cell
                >
              </Table.Row>
            {/each}
          </Table.Body>
        </Table.Root>
      {:else}
        <div class="text-center py-4 text-gray-500">
          No names added yet. Enter names in the text area above.
        </div>
      {/if}
    </Card.Content>
  </Card.Root>

  <!-- Information Section -->
  <div class="mt-12 prose max-w-5xl mx-auto">
    <h2 class="text-2xl font-bold mb-4">
      Learn more about the random name winner tool.
    </h2>
    <section id="prize-winners" class="mb-8">
      <h3 class="text-xl font-bold mb-2">
        Why use Promo Amp's random name picker?
      </h3>
      <ul class="list-disc list-inside">
        <li>
          <span class="font-bold">Fast & Easy:</span> Enter names, click a button,
          and get instant results.
        </li>
        <li>
          <span class="font-bold">Fair & Unbiased:</span> Uses RANDOM.ORG's true
          random number generator to ensure randomness. ensure randomness.
        </li>
        <li>
          <span class="font-bold">Versatile:</span> Ideal for giveaways, team selection,
          classroom activities, and more.
        </li>
        <li>
          <span class="font-bold">Handles Large Lists:</span> Supports up to 10,000
          names in a single draw.
        </li>
      </ul>

      <p class="mt-4">
        Whether you need a random name generator for giveaways, prize draws, or
        team selection, our tool provides a seamless and accurate experience.
        Try our random name picker today and simplify your selection process!
      </p>
    </section>
    <section id="using" class="mb-8">
      <h3 class="text-xl font-bold mb-2">
        How do I use the random name winner?
      </h3>
      <p>
        If you need to generate a single random name from a list, our random
        name picker makes the process seamless. Simply enter your list of
        names—one per line—into the tool. You can copy and paste from a
        spreadsheet for efficiency. Our random name generator supports up to
        10,000 names. Once your list is ready, click the "Pick a Random Name"
        button, and our advanced algorithm, powered by RANDOM.ORG, will select a
        name fairly and without bias. Our random selector functions similarly to
        rolling a die with as many sides as there are names—ensuring each has an
        equal probability of being chosen.
      </p>
    </section>

    <section id="multiple" class="mb-8">
      <h3 class="text-xl font-bold mb-2">
        How do I pick multiple random names?
      </h3>
      <p>
        If you need to select multiple names instead of just one, the process is
        just as simple. Enter your list of names and adjust the "Number of names
        to pick" field from 1 to however many you need. The random name picker
        allows you to draw up to 1,000 names at once.
      </p>
      <p class="mt-4">
        Once you've generated multiple names, you can easily copy and paste them
        for further use (Ctrl+A on PC or Command+A on Mac for quick selection).
      </p>
    </section>

    <section id="truly-random" class="mb-8">
      <h3 class="text-xl font-bold mb-2">
        How does the random name picker ensure truly random selection?
      </h3>
      <p>
        Our random name picker assigns an incremental ID to each entry and uses
        RANDOM.ORG's true random number generator (RNG) to select names. This
        guarantees fairness and eliminates any potential bias. Unlike physical
        randomizing methods like dice or coins, which may have imperfections,
        our software ensures an unbiased and statistically reliable selection.
      </p>
      <p class="mt-4">
        Our random name selector has been tested extensively through
        simulations, verifying that each name has an equal chance of being
        chosen in every draw.
      </p>
    </section>

    <section id="applications" class="mb-8">
      <h3 class="text-xl font-bold mb-2">
        What are some applications of a random name picker?
      </h3>
      <p>
        There are countless scenarios where a random name picker tool can be
        invaluable.
      </p>

      <ol class="list-decimal list-inside">
        Here are some of the most common use cases:
        <li class="mt-4">
          <b>Selecting Prize Winners at Random:</b> For contests, giveaways, or charity
          raffles, a random name generator ensures fairness when selecting winners.
          Just enter the participants' names, and the tool will randomly choose the
          winners, guaranteeing equal chances for all.
        </li>
        <li class="mt-4">
          <b>Picking Teams Randomly:</b> A random name selector is also great for
          organizing teams for sports, board games, or online multiplayer matches.
          While a dedicated random team generator might be ideal for large team distributions,
          this tool works perfectly when dividing people into two equal teams. For
          example, input 22 soccer players and let the tool randomly select 11 for
          one team, leaving the remainder as their opponents.
        </li>
      </ol>
    </section>

    <section id="using" class="mb-8">
      <h3 class="text-xl font-bold mb-2">What is Promo Amp?</h3>
      <p>
        Promo Amp makes running giveaways effortless. Easily launch, manage, and
        track your competitions - no hassle, just results. This Random Name
        Picker tool is offered as a free tool.
      </p>
      <p class="mt-4">
        <a href="/" class="text-purple-500">Learn more about Promo Amp</a>
        or
        <a href="/sign-up" class="text-purple-500"
          >launch a free giveaway in minutes</a
        >.
      </p>
    </section>
  </div>
</div>

<section class="bg-white py-16">
  <div class="max-w-6xl mx-auto px-4 sm:px-6">
    <!-- Testimonial -->
    <div class="text-center">
      <p class="text-2xl text-gray-600 italic mb-8">
        "I've been using Promo Amp for a few months now and it's been a
        game-changer for my business. I've launched a few giveaways and they've
        been a huge success. The tool is easy to use and the results are great.
        I highly recommend it!"
      </p>
      <div class="flex items-center justify-center gap-4">
        <img
          src="/images/testimonials/tom.jpeg"
          alt="Profile"
          class="w-12 h-12 rounded-full"
        />
        <div class="text-left">
          <div class="font-bold">Tom Mcleod</div>
          <div class="text-gray-600">Founder of Bitshift</div>
        </div>
      </div>
    </div>
    <!-- CTA -->
    <div class="text-center mt-32">
      <CtaImage />
      <h2 class="text-3xl md:text-4xl font-bold mb-6">
        Run a giveaway for free
      </h2>
      <p class="text-gray-600 mb-8">
        It's fast and hassle-free—no coding needed. If you can click and type,
        you're ready to go!
      </p>
      <a
        href="/sign-up"
        rel="external"
        class="inline-block bg-purple-500 hover:bg-purple-600 text-white font-medium rounded-full px-8 py-3 transition-colors"
        data-svelte-h="svelte-87bpmk">Launch a free giveaway →</a
      >
    </div>
  </div>
</section>

<style>
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  .animate-bounce {
    animation: bounce 1s ease infinite;
  }
</style>
